export default {
  microsite: {
    token: 'pk.eyJ1IjoiaG9zdGVsd29ybGRwcm9kdWN0IiwiYSI6ImNqc3VmNmRxOTE4MDM0M3M5MGk3czk2ZzIifQ.xsWIVAnUNKLtwrnAfCVvbQ',
    style: 'mapbox/streets-v11',
    zoom: 13,
  },
  fab: {
    token: 'pk.eyJ1IjoiaG9zdGVsd29ybGRwcm9kdWN0IiwiYSI6ImNqdXR1NHBxdDA1cGc0ZW54MWFyOGRpZTQifQ.j9wsue6dC2EVRzS_WUVIPQ',
    style: 'mapbox://styles/hostelworldproduct/clhytyeyp02b901qyg7k68dg8',
  },
  blackFriday: {
    token: 'pk.eyJ1IjoiaG9zdGVsd29ybGRwcm9kdWN0IiwiYSI6ImNsOTJtZWo0djA1YWozcG83NXNjNmQ5cW4ifQ.LiP0n-CIZFU0mKx0zzC5Ng',
    style: 'mapbox://styles/mapbox/dark-v10',
  },
  imageApiUrl: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/',
  fitBoundsOptions: {
    maxZoom: 15,
    padding: {
      top: 50,
      bottom: 30,
      left: 50,
      right: 50,
    },
  },
};
